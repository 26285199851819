import { Ability, AbilityBuilder } from "@casl/ability"

export const getAbilities = user => {
  const { can, rules } = new AbilityBuilder(Ability)
  if (user) {
      if (user.perfil === 'Administrador' || user.perfil === 'Desenvolvedor') {
        can('view', 'all')
        can('cadastro-pacientes', 'Cadastro')
        can('cadastro-usuarios', 'Cadastro')
      } else if (user.perfil === 'Supervisor') {
        can('view', 'Default')
        can('cadastro-usuarios', 'Cadastro')
        can('cadastro-pacientes', 'Cadastro')
        can('view', 'Autorizacao')
        can('view', 'Historico')
        can('view', 'Pendencias')
        can('view', 'Pedidos de Exame')
      } else if (user.perfil === 'Recepção') {
        can('view', 'Default')
        can('cadastro-pacientes', 'Cadastro')
        can('view', 'Autorizacao')
        can('view', 'Historico')
        can('view', 'Pendencias')
        can('view', 'Pedidos de Exame')
      } else if (user.perfil === 'Default') {
        can('view', 'Default')
      }
  } else {
      can('view', 'Auth')
      can('view', 'Envio Pedido')
  }
  
  return rules
}

