import { useState, createContext, useEffect, useContext } from 'react'
import { toast } from 'react-toastify'
import CustomToast from '../../components/custom-toast'
import { AppApi } from '@services/api-routes'
import { getUserData } from '../Utils'
import { GlobalLoaderContext } from './GlobalLoader'

const ClientContext = createContext()

const default_pendencies = {
  operadoras: false,
  profissionais: false,
  cadastro: false,
  cadastroCompleto: false
}

const ClientProvider = ({ children }) => {
  const { setGlobalLoader } = useContext(GlobalLoaderContext)

  const userData = getUserData()

  const [client, setClient] = useState(null)
  const [user, setUser] = useState(null)
  const [user_unities, setUserUnities] = useState(null)
  const [pendencies, setPendencies] = useState(default_pendencies)
  const [is_complete_register_modal_open, setIsCompleteRegisterModalOpen] = useState(false)

  const getClient = async clientId => {
    const resp = await AppApi.client.getById(clientId)
    if (resp) {
        setClient(resp)
    } else {
      setClient([])
    }
  }

  const getPendencies = async clientId => {
    const resp = await AppApi.client.getPendencies(clientId)
    if (resp) {
      setPendencies(resp)
    }
    return resp
  }

  const getUserById = async user_id => {
    const resp = await AppApi.cadastros.usuario.getByIdUserContext(user_id)
    if (resp) {
      setUser(resp)
    }
  }

  const updateUserUnities = async () => {
    const resp = await AppApi.cadastros.unidades.getUsuarioUnidade(userData.id)
    if (resp) {
      const newUserUnities = {
        ...user_unities,
        unidadePadrao: resp.find(unidade => unidade.padrao) || user_unities.unidadePadrao,
        unidades: resp
      }
      setUserUnities(newUserUnities)
      const newUserData = {
          ...userData,
          ...newUserUnities
      }
      localStorage.setItem('userData', JSON.stringify(newUserData))
    }
  }

  const getUserUnities = async data => {
    setUserUnities({
      unidadePadrao: data.unidadePadrao,
      unidades: data.unidades
    })
  }

  const handleUserData = async data => {
    getUserUnities(data)
    const reqs = [
      getClient(data.clienteId),
      getUserById(data.usuarioUnidadeId),
      getPendencies(data.clienteId)
    ]

    await Promise.all(reqs)
  }

  const getData = async () => {
    setGlobalLoader(true)
    if (userData) {
      await handleUserData(userData)
    }
    setGlobalLoader(false)
  }

  useEffect(() => {
    (async () => await getData())()
  }, [])

  const handleResetClient = () => {
    setClient(null)
    setUser(null)
    setUserUnities(null)
    setPendencies(default_pendencies)
  }

  const checkPendency = async (pendency, clientId) => {
    const resp = await getPendencies(clientId)
    if (resp && !pendencies[pendency] && resp[pendency]) {
        toast.success(
          <CustomToast
            title='Sucesso!'
            message={`Pendência de ${pendency[0].toUpperCase() + pendency.slice(1)} concluida!`}
          />
        )
        return resp
    }
  }

  const hasMinPendencies = () => {
    let done_pendencies = 0
    if (pendencies.cadastro) {
        done_pendencies += 1
    }
    if (pendencies.profissionais) {
        done_pendencies += 1
    }
    if (pendencies.operadoras) {
        done_pendencies += 1
    }
    
    return done_pendencies < 2
  }

  const value = {
    client, 
    setClient, 
    getClient, 
    pendencies,
    getPendencies,
    checkPendency,
    user,
    setUser,
    user_unities,
    setUserUnities,
    is_complete_register_modal_open, 
    setIsCompleteRegisterModalOpen,
    handleUserData,
    handleResetClient,
    hasMinPendencies,
    updateUserUnities
  }

  return (
    <ClientContext.Provider value={value}>
      {children}
    </ClientContext.Provider>
  ) 
}

export { ClientContext, ClientProvider }
