import { useState, createContext } from 'react'

const IntegrationContext = createContext()

const IntegrationProvider = ({ children }) => {
  const [integrationActive, setIntegrationActive] = useState(false)

  const handleResetIntegration = () => {
    setIntegrationActive(false)
  }

  const value = {
    handleResetIntegration,
    integrationActive,
    setIntegrationActive
  }

  return (
    <IntegrationContext.Provider value={value}>
      {children}
    </IntegrationContext.Provider>
  )
}

export { IntegrationContext, IntegrationProvider }
