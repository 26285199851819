import { useState, createContext } from 'react'

const NavbarContext = createContext()

const NavbarProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(true)

  const handleChangeNavbar = (val) => {
    setIsActive(val)
  }

  const value = {
    handleChangeNavbar,
    isActive
  }

  return (
    <NavbarContext.Provider value={value}>
      {children}
    </NavbarContext.Provider>
  )
}

export { NavbarContext, NavbarProvider }
